import React from "react";

import AppStore from "../assets/imgs/app-store.png";
import PlayStore from "../assets/imgs/play-store.png";
import classes from "../styles/Header.module.css";

const Header = () => {

  return (
    <div id="#header" className={classes.Header}>
      <div className={classes.Left}>
        <div className={classes.Title}>
          <div>Vos médicaments,</div>
          <div>en un clic !</div>
        </div>
        <div className={classes.SubTitle}>
          Les pharmacies de garde n'ont toujours pas les produits que nous
          cherchons. Ne vous déplacez plus inutilement. Sachez dans quelle(s)
          pharmacie(s) vos médicaments se trouvent !
        </div>
        <div className={classes.StoresContainer}>
          <div className={classes.ImageContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=tech.cladjen.lavev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={PlayStore} className={classes.Store} alt="" />
            </a>
          </div>
          <div className={classes.ImageContainer}>
            <a
              href="https://apps.apple.com/app/lavev/id1598380106"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStore} className={classes.Store} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.Right} />
    </div>
  );
};

export default Header;
