import React from 'react';

import classes from '../styles/NavBar.module.css';
import Logo from '../assets/imgs/logo.svg';

const NavBar = () => {

    return(
        <div className={classes.NavBar}>
            <div className={classes.LogoContainer}>
                <img src={Logo} className={classes.Logo} alt='' />
            </div>
        </div>
    );
};

export default NavBar;