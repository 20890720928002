import React from 'react';

import classes from './styles/Main.module.css';

import NavBar from './components/NavBar';
import Header from './components/Header';
import Kecako from './components/Kecako';
import Fonctionalities from './components/Fonctionalities';
import Footer from './components/Footer';
import About from './components/About';

const App = () => {

    return(
        <div className={classes.Main}>
            <NavBar />
            <Header />
            <Kecako />
            <Fonctionalities />
            <About />
            <Footer />
        </div>
    );
};

export default App;