import React from "react";

import classes from "../styles/Fonctionalities.module.css";
import Title from "./Title";

const Fonctionalities = () => (
  <div id="#fontionalities" className={classes.Fonctionalities}>
    <Title>Avantages</Title>
    <div className={classes.Item}>
      <div className={classes.Left}>
        <div className={classes.ItemTitle}>Localiser</div>
        <div className={classes.ItemText}>
          Ne vous déplacez plus inutilement. Retrouvez la pharmacie la plus
          proche où vos médicaments se trouvent.
        </div>
      </div>
      <div className={classes.Localisation} />
    </div>
    <div className={classes.Item}>
      <div className={classes.Left}>
        <div className={classes.ItemTitle}>Payer en ligne</div>
        <div className={classes.ItemText}>
          Payez vos médicaments en ligne à l'aide du porte-monnaie électronique
          intégré.
        </div>
      </div>
      <div className={classes.Paiement} />
    </div>
    <div className={classes.Item}>
      <div className={classes.Left}>
        <div className={classes.ItemTitle}>Livraison</div>
        <div className={classes.ItemText}>
          L'option de livraison n'est pas encore prise en compte. Nous y
          travaillons en étroite collaboration avec nos officines partenaires.
        </div>
      </div>
      <div className={classes.Livraison} />
    </div>
    <div className={classes.Item}>
      <div className={classes.Left}>
        <div className={classes.ItemTitle}>Assurance (bientôt)</div>
        <div className={classes.ItemText}>
          Prise en compte automatique de votre assurance si vous en possédez.
        </div>
      </div>
      <div className={classes.Assurance} />
    </div>
  </div>
);

export default Fonctionalities;
