import React from "react";

import classes from "../styles/About.module.css";
import Title from "./Title";

const About = () => (
  <div id="#about" className={classes.About}>
    <Title>À propos</Title>
    <div className={classes.ItemText}>
      <p>
        LAVEV est un produit imaginé, conçu et opérationnalisé par{" "}
        <a
          href="https://www.cladjen.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          CLADJEN SARL
        </a>
        , une entreprise burkinabè spécialisée dans le développement de
        solutions Blockchain.
      </p>
      <p>
        Ayant pour mission d'aider les Entreprises et Startups à{" "}
        <strong>
          profiter du plein potentiel de la Technologie des Registres Distribués
          (Blockchain)
        </strong>
        , CLADJEN travaille également à mettre sur pied des solutions innovantes
        pour l'amélioration du quotidient des populations. Parmi ces solutions,
        à côté de LAVEV, vous avez :
      </p>
      <ul>
        <li>
          <a
            href="https://tontinum.cladjen.tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            TONTINUM
          </a>{" "}
          : une plateforme d'organisation de tontines en ligne et de vente à
          tempérament;
        </li>
        <li>
          <a
            href="https://www.libwo.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Libwo
          </a>{" "}
          : une application de gestion de codes USSD, commandes et réservations,
          mobile money, vente et achat à tempérament;
        </li>
        <li>
          <a
            href="https://atika.cladjen.tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            ATIKA
          </a>{" "}
          : la plateforme qui vous permet de payer une faible prime d'assurance
          et être indemnisé en moins de 24h.
        </li>
      </ul>
      <p>
        TONTINUM a été incubé au sein de l'incubateur digital de l'Université
        Joseph Ki Zerbo,{" "}
        <a
          href="https://www.incubuo.tech/"
          target="_blank"
          rel="noopener noreferrer"
        >
          INCUB@UO
        </a>
        , au compte de la première cohorte du "Projet Femmes-Jeunes
        Entreprenants et Citoyenneté" du <strong>PNUD</strong>.
      </p>
    </div>

    <Title>Notre actualité</Title>
    <div className={classes.videoContainer}>
      <iframe
        className={classes.Youtube}
        src="https://www.youtube.com/embed/zUAV_FyC2yk"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
);

export default About;
