import React from 'react';

import classes from '../styles/Kecako.module.css';
import Title from './Title';

const Kecako = () => (
    <div id='#fonctionnement' className={classes.Kecako}>
        <Title>Comment ça marche ?</Title>
        <div className={classes.Item}>
            <div className={classes.Left}>
                <div className={classes.ItemText}>
                    <p>Juste une image/capture de votre ordonnance et l'application vous notifiera la ou les pharmacie(s) où les produits presrits sont disponibles.</p>
                    <p>Par ailleurs, si vous connaissez les noms de vos produits, au lieu de fournir une image, vous indiquez simplement les noms.</p>
                    <p>Si vous êtes un pharmacien, vous avez la possibilité d'envoyer des requêtes aux grossistes qui utilisent aussi l'application et à d'autres pharmacies (pour vous-mêmes ou pour soulager un patient).</p>
                </div>
            </div>
            <div className={classes.Right}>
                <div className={classes.ParOrdonnance} />
                <div className={classes.ParNom} />
            </div>
        </div>
    </div>
);

export default Kecako;