import React from "react";

import classes from "../styles/Footer.module.css";
import logo from "../assets/imgs/logo.svg";
import phone from "../assets/imgs/phone.svg";
import email from "../assets/imgs/email.svg";
import location from "../assets/imgs/location.svg";
import facebook from "../assets/imgs/facebook.svg";
import linkedin from "../assets/imgs/linkedin.svg";

const Footer = () => (
  <div className={classes.Footer}>
    <img src={logo} className={classes.Logo} alt="" />
    <div
      style={{
        marginBottom: "10px",
        color: "#00a99d",
        fontSize: "1.1rem",
        textAlign: "center",
      }}
    >
      Ayez vos médicaments, en un clic!
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "35px" }}>
      <img src={location} style={{ width: "15px" }} alt="" />
      <span
        style={{ fontSize: ".9rem", marginLeft: "12px", textAlign: "center" }}
      >
        Ouagadougou, Burkina Faso
      </span>
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "15px" }}>
      <img src={phone} style={{ width: "20px" }} alt="" />
      <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>
        (+226) 25 39 37 81
      </span>
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "15px" }}>
      <img src={email} style={{ width: "20px" }} alt="" />
      <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>
        contact-bf@lavev.africa
      </span>
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "15px" }}>
      <a
        href="https://www.facebook.com/lavev.africa"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#3f3d56",
          textDecoration: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={facebook} style={{ width: "20px" }} alt="" />
        <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>Facebook</span>
      </a>
    </div>
    <div className={classes.FlexRow} style={{ marginTop: "15px" }}>
      <a
        href="https://www.linkedin.com/company/lavev"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#3f3d56",
          textDecoration: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={linkedin} style={{ width: "20px" }} alt="" />
        <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>LinkedIn</span>
      </a>
    </div>
    <div style={{ marginTop: "40px", color: "#3f3d56", textAlign: "center" }}>
      © CLADJEN {new Date().getFullYear()} - Tous droits réservés
    </div>
  </div>
);

export default Footer;
