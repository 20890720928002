import React from 'react';

import classes from '../styles/Main.module.css';

const Title = (props) => (
    <div className={classes.Title}>
        {props.children}
    </div>
);

export default Title;